<template>
  <div>
    <v-container fluid class="py-8">
      <Notify ref="myNotify" :details="message"></Notify>
      <v-row>
        <!-- <v-col cols="12">
          <h5 class="text-h3 text-typo font-weight-bold ms-4 mb-3">
            {{ $t("cycle.Cycles") }}
          </h5>
        </v-col>-->
        <v-col cols="12">
          <v-col cols="12">
            <v-card color="light">
              <v-app-bar color="light" class="border-left-primary">
                <v-toolbar-title id="title">
                  {{ $t("cycle.Cycles") }}
                </v-toolbar-title>
                <button
                  class="py-5 px-6 mb-0 ms-auto"
                  v-if="
                    $store.getters['auth/str_per'].indexOf('cycle-create') > -1
                  "
                  @click="add_cycle()"
                >
                  <v-icon class="custome-btn btn-primary">fas fa-plus</v-icon>
                </button>
              </v-app-bar>
              <Table
                class="print"
                @event_btn="event_btn"
                :loading="loading"
                @action="action"
                :btns="data"
                :btn_loading="btn_loading"
                :headers="header"
                :items="cycles"
                :paginate="paginate"
                :btn_table="btn_table"
                @change_pre_page="ChangePrePage"
                @get_data_paginate="handlePageChange"
              ></Table>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
      <!-- <Table
        :loading="loading"
        @action="action"
        :btns="data"
        :btn_loading="btn_loading"
        :headers="header"
        :items="cycles"
        :paginate="paginate"
        :btn_table="btn_table"
        @change_pre_page="ChangePrePage"
        @get_data_paginate="handlePageChange"
      ></Table>-->
    </v-container>
  </div>
</template>
<script>
import Table from "../Components/PaginationTable.vue";
import Notify from "../Components/Old/Notify.vue";
import CycleService from "../../services/cycle.service";
export default {
  name: "Cycle-Page",
  components: {
    Table,
    Notify,
  },
  data() {
    return {
      btn_loading: {
        excel: false,
        print: false,
      },
      btn_table: {
        excel: false,
        print: true,
      },
      loading: true,
      message: {
        msg: null,
        type: null,
      },
      data: [
        // {
        //   type: "icon",
        //   text: "details",
        //   color: "bg-gradient-info",
        //   icon: "mdi-eye",
        //   permission: "cycle-details",
        // },
        {
          type: "icon",
          text: "edit",
          color: "bg-gradient-success",
          icon: "mdi-pencil",
          permission: "cycle-update",
        },
        {
          type: "icon",
          text: "destroy",
          color: "bg-gradient-danger",
          icon: "mdi-delete",
          permission: "cycle-delete",
        },
      ],
      header: [
        {
          text: this.$i18n.t("cycle.title in english"),
          value: "title",
          align: "center",
        },
        {
          text: this.$i18n.t("cycle.title in arabic"),
          value: "title_ar",
          align: "center",
        },
        {
          text: this.$i18n.t("cycle.orders_count"),
          value: "orders_count",
          align: "center",
        },
        // {
        //   text: this.$i18n.t("cycle.Description in english"),
        //   value: "description",
        //   align: "center",
        // },
        // {
        //   text: this.$i18n.t("cycle.Description in arabic"),
        //   value: "description_ar",
        //   align: "center",
        // },
        { text: this.$i18n.t("cycle.Start"), value: "start", align: "center" },
        {
          text: this.$i18n.t("cycle.Expire"),
          value: "expire",
          align: "center",
        },
        {
          text: this.$i18n.t("general.Action"),
          value: "btns",
          align: "center",
        },
      ],
      cycles: [],
      paginate: {
        page: 1,
        itemsPerPage: 0,
        total: 0,
        row_pre_page: false,
      },
    };
  },
  methods: {
    event_btn(name) {
      if (name == "excel") {
        this.export_excel();
      } else if (name == "print") {
        this.print_table();
      }
    },
    print_table() {
      document.querySelector("table").classList.add("print");
      window.print();
    },
    get_cycles() {
      console.log("cycle1");
      return CycleService.get_cycles(this.paginate.page).then(
        (response) => {
          // console.log(response);
          this.cycles = response.data.data;
          this.paginate.total = response.data.total;
          this.paginate.itemsPerPage = parseInt(response.data.per_page);
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.loading = false;
          this.message = {
            msg: this.$i18n.t("general.there is problem"),
            type: "Danger",
          };
        }
      );
    },
    action(id, name) {
      if (name == "edit") {
        this.edit(id);
      } else if (name == "destroy") {
        this.destroy(id);
      } else if (name == "details") {
        this.details(id);
      }
    },
    add_cycle() {
      return this.$router.push({ name: "NewCycle" });
    },
    edit(id) {
      this.$router.push({ path: `/cycle/edit-cycle/${id.toString()}` });
    },
    destroy(id) {
      this.$swal({
        title: this.$i18n.t("general.Are you sure?!"),
        text: this.$i18n.t("You won't be able to revert this!"),
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: this.$i18n.t("general.Yes, delete it!"),
        cancelButtonText: this.$i18n.t("general.No, cancel!"),
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          return CycleService.destroy_cycle(id).then(
            (response) => {
              // console.log(response)
              this.cycles = this.cycles.filter((v) => v.id != id);
              this.$swal.fire(
                this.$i18n.t("general.Deleted!"),
                response.data.message,
                "success"
              );
            },
            (error) => {
              console.log(error);
              this.$swal.fire(
                this.$i18n.t("general.Error"),
                this.$i18n.t("general.There error please try again"),
                "error"
              );
            }
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.fire(
            this.$i18n.t("general.Cancelled"),
            this.$i18n.t("general.Cancelled Delete"),
            "error"
          );
        }
      });
    },
    details(id) {
      this.$router.push({ path: `/cycle/details-cycle/${id.toString()}` });
    },
    handlePageChange(page) {
      console.log(page);
      this.paginate.page = page;
      this.get_cycles();
    },
    ChangePrePage(page) {
      this.paginate.itemsPerPage = parseInt(page);
      this.paginate.page = 1;
      this.get_cycles();
    },
  },
  created() {
    if (this.$route.params.message) {
      this.message = this.$route.params.message;
      //
    }
  },
  mounted() {
    this.get_cycles();
    document.title = this.$i18n.t("cycle.Cycles");
  },
};
</script>
<style>
@media print {
  .print {
    /* border: 1px solid black; */
    border: none;
    border-collapse: collapse;
  }
  .print td {
    border-left: 1px solid #000;
    border-right: 1px solid #000;
  }

  .print td:first-child {
    border-left: none;
  }

  .print td:last-child {
    border-right: none;
  }

  tr > td:last-of-type {
    display: none;
  }
  tr > th:last-of-type {
    display: none;
  }
}
</style>
